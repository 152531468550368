.content[data-v-ebb972da] {
  height: 100%;
}
.el-input-number.el-input-number--small[data-v-ebb972da] {
  width: 100%;
}
[data-v-ebb972da].listDialog #gridLayout {
  height: calc(100% - 60px);
}
[data-v-ebb972da].listDialog #gridLayout .avue-crud {
  height: 100%;
}
[data-v-ebb972da].listDialog #gridLayout .avue-crud .el-card {
  height: 100%;
}
[data-v-ebb972da].listDialog #gridLayout .avue-crud .el-card .el-card__body {
  height: 100%;
}
[data-v-ebb972da].listDialog #gridLayout .avue-crud .el-card .el-card__body .el-form {
  height: 100%;
}
[data-v-ebb972da].listDialog #gridLayout .avue-crud .el-card .el-card__body .el-form .el-table {
  height: 100% !important;
}
[data-v-ebb972da].listDialog #gridLayout .avue-crud .avue-crud__pagination {
  height: auto;
}